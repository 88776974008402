//;
//(function() { -> sinon les autres scripts n'ont pas accès aux fonctions situées dans ce fichier
/* Description :                        */
/*  - Scripts CM 2017                   */
/* Responsabilité :                     */
/*  - Équipe DIGITALE CM-CIC/FXL         */
/* Historique :                         */
/*  - 19/08/2019 : maj FX Ajout du test :visible lors de la détection de la hauteur du header fixe         */
/*                                                          */
/****************************************/
/****************************************/
/*
Summary
1. Rajouter le retour à la page précédente
2. Bouton Haut de la page
3. Menu espace
4. Gestion des ancres et des menus sticky
5. Ouverture des liens externes en target blank

/*==========================================================================
1. Rajouter le retour à la page précédente
========================================================================== */
// function initAddBack() {
// 	$("article > header h1:first").prepend('<a class="back" href="#js"><span class="invisible">Retour</span></a>');
// 	$('header a.back').click(function () {
// 		if (document.referrer.indexOf('creditmutuel.fr') !== -1) {
// 			history.back();
// 			return false;
// 		}
// 	});
	// var landing = $("article").parent('#landing');
	// var parent = $("article > header h1:first").parent();
	// var h1 = $("article > header h1:first").addClass('back-heading');
	// var container = $('<div class="back-container"></div>');
	// var link = $('<a class="back" href="#js" onclick="history.back(); return false;"><span class="invisible">Retour</span></a>');
	// container.prepend(h1);
	// container.prepend(link);

	// if (landing.length) {
	// 	var surtitle = landing.find('.surtitle');
	// 	container.insertAfter(surtitle);
	// } else {
	// 	parent.prepend(container);
	// }

	// $('header a.back').click(function () {
	// 	if (document.referrer.indexOf('creditmutuel.fr') !== -1) {
	// 		history.back();
	// 		return false;
	// 	}
	// });
// }
// $(document).ready(initAddBack);

// $(document).ready(function () {
// 	window.history.length > 0 && $("[data-back='true']").each(function () {
// 		var parent = $(this).parent();
// 		var container = $('<div class="back-container"></div>');
// 		var h1 = $(this).detach();
// 		h1.addClass('back-heading');
// 		var link = $('<a href="#js" onclick="history.back(); return false;" class="h-back" title="Retour"></a>');
// 		container.prepend(h1);
// 		container.prepend(link);
// 		parent.prepend(container);
// 	})
// })

// $(document).ready(function () {
// 	window.history.length > 0 && $("[data-back='true']").each(function () {
// 		$(this).prepend('<a href="#js" onclick="history.back(); return false;" class="h-back" title="Retour"></a>')
// 	})
// })




/*==========================================================================
2. Bouton Haut de la page
========================================================================== */
/*
$(document).ready(function () {
	$("body").append("<a href=\"#js\" class=\"top-btn\">Haut de page</a>");
	$(".top-btn").on('click', function (event) {
		event.preventDefault();
		$('body,html').animate({
			scrollTop: 0
		}, 500);
	});
});
$(window).scroll(function () {
	if ($(window).scrollTop() > 300)
		$(".top-btn").addClass('top-btn-visible');
	else
		$(".top-btn").removeClass('top-btn-visible');
});
*/


/*==========================================================================
3. Menu espace
========================================================================== */

$(document).ready(function () {
	var space_name = "";
	var space_link = "";
	var p = $(".cc_menu_espace .e_select > a");
	if (p.length) {
		space_name = p.html();
		space_link = p.attr("href");
	} else {
		if ($("nav > ul > .e_menuinline > a").length) {
			space_name = $("nav > ul > .e_menuinline > a").html();
			space_link = $("nav > ul > .e_menuinline > a").attr("href");
		}
	}

	if (space_name !== "") {
		if ($(".cc_menu_espace > a").length)
			$(".cc_menu_espace > a").html(space_name);
	} else {
		$(".cc_menu_espace > a").html("Accueil");
	}
	if (space_link !== "") {
		if ($(".cc_menu_espace > a").length)
			$(".cc_menu_espace > a").attr("href", space_link);
	}

});



/*==========================================================================
4. Gestion des ancres et des menus sticky
========================================================================== */

//Gestion du menu Sticky
//Thanks to http://callmenick.com/post/single-page-site-with-smooth-scrolling-highlighted-link-and-fixed-navigation
function initMenuSticky() {
	var headerHeight = $('#ei_tpl_head').outerHeight(); //hauteur du header
	var hasMenuAside = false;
	var hasMenuIntra = false;

	//menu sticky Aside CM2017
	if ($(".cc.hasAside .sticky").length) {
		hasMenuAside = true;
		var menuAsideOffsetTop = $(".cc.hasAside .sticky").offset().top;

		//on place dans un tableau les ancres des liens du menu
		var menuAsideChildren = $(".cc.hasAside .sticky li").children();
		var menuAsideHashes = []; // create the empty menuAsideHashes
		for (var i = 0; i < menuAsideChildren.length; i++) {
			var aChild = menuAsideChildren[i];
			var ahref = $(aChild).attr('href');
			menuAsideHashes.push(ahref);
		}

		//par défaut le premier élément du menu est sélectionné
		$(".cc.hasAside .sticky ul li:first-child").addClass("sel");
	}

	if (hasMenuAside) {
		//gestion du menu sticky au redimentionnement de la fenêtre
		$(window).resize(function () {
			MenuAsideSticky(headerHeight, menuAsideOffsetTop, menuAsideHashes);
		});

		$(window).scroll(function () {
			MenuAsideSticky(headerHeight, menuAsideOffsetTop, menuAsideHashes);
		});
	}
}

function MenuAsideSticky(headerHeight, menuAsideOffsetTop, menuAsideHashes) {
	var menuAsideOffsetLeft;
	var menuAsideWidth;

	//gestion du menu sticky
	if ($(window).width() > 970) {
		menuAsideOffsetLeft = $(".cc.hasAside .body").offset().left;
		menuAsideWidth = $(".cc.hasAside .body > section").css("margin-left");
		if ($(window).scrollTop() > menuAsideOffsetTop)
			$(".cc.hasAside .sticky").addClass('fixed').css('top', headerHeight).css('left', menuAsideOffsetLeft).css('width', menuAsideWidth);
		else
			$(".cc.hasAside .sticky").removeClass('fixed').css('top', 'auto').css('left', 'auto').css('width', '30%');
	} else
		$(".cc.hasAside .sticky").removeClass('fixed').css('top', 'auto').css('left', 'auto').css('width', '30%');

	//gestion de l'activation des liens du menu en fonction de la position de la page
	var windowPos = $(window).scrollTop(); // offset jusqu'en haut de la page
	var windowHeight = $(window).height(); // Hauteur de la fenêtre
	var docHeight = $(document).height();

	for (var i = 0; i < menuAsideHashes.length; i++) {
		var targetId = menuAsideHashes[i];
		if ($(targetId).length) {
			var divPos = $(targetId).offset().top; // offset entre l'ancre et le haut de la page
			if (windowPos >= (divPos - headerHeight - 60)) {
				$(".cc.hasAside .sticky li.sel").removeClass("sel");
				$("a[href='" + targetId + "']").parent().addClass("sel");
			} else {
				$("a[href='" + targetId + "']").parent().removeClass("sel");
			}
		}
	}
	//par défaut le premier élément du menu est sélectionné
	if (!$(".cc.hasAside .sticky ul li.sel").length)
		$(".cc.hasAside .sticky ul li:first-child").addClass("sel");
}

/*
$(document).ready(function () {
	initMenuSticky();
});
*/

function Scroll2ID(id) {
	//décalage lié au header sticky
	var stickyHeight = 0;

	if (typeof $("#ei_tpl_head").attr('data-sticky') == 'undefined')
		stickyHeight = $('#ei_tpl_head').outerHeight(); //hauteur du header

	$("*[data-sticky]:visible").each(function () {
		stickyHeight += $(this).outerHeight();
	});

	//
	var target = $("#" + id);
	if (target.length) {
		target.focus();
		$('html,body').scrollTop(target.offset().top - stickyHeight);
	}
}

var Scroll2Hash = function() {

	//décalage lié au header sticky
	var stickyHeight = 0;

	if (typeof $("#ei_tpl_head").attr('data-sticky') == 'undefined')
		stickyHeight = $('#ei_tpl_head').outerHeight(); //hauteur du header

	$("*[data-sticky]:visible").each(function () {
		stickyHeight += $(this).outerHeight();
	});

	var anchors = $('a[href*=#]:not([href=#]):not([data-smoothscrolling=false])');

	//FX: modif de l'appel du callback car sinon il se déclenchait 2 fois (cf http://stackoverflow.com/questions/8790752/callback-of-animate-gets-called-twice-jquery)
	//FX: ajout du event dans le fonction pour pouvoir désactiver l'action par défaut (aller à l'ancre) qui se lance parfois avec IE
	anchors
		.filter(function () {
			return location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
			&& location.hostname == this.hostname;
		})
		.off("click.smoothscroll")
		.on("click.smoothscroll", function (event) {
			event.preventDefault(); //FX: desactivation de l'action par défaut
			var sId = decodeURIComponent(this.hash);
			sId = sId.replace(/\:/g, "\\:"); // Pour évitter que les id à la #!@ de devbooster ne fasse cracher jquery
			var target = $(sId);
			target = target.length ? target : $('[name=\'' + sId.slice(1) + '\']');
			if (target.length) {
				//par défaut, pas de décalage
				var iDecal = 0;
				if (!target.hasClass("ei_decal_anchor")) {
					//s'il y a la classe ei_decal_anchor, le décalage est déjà géré et on ne fait rien de spécial, sinon, on exploite le décalage lié au header sticky
					iDecal = stickyHeight + 10; //on ajoute 10px pour éviter d'être collé au header.
				}

				// on calcule la distance à parcourir (en valeur absolue)
				var iDuration = Math.abs($("body,html").scrollTop() - (target.offset().top - iDecal));
				// cette distance sert de temps pour l'animation, mais on bride à 1s max. Vitesse de déplacement : 1000px/s si distance <= 1000px
				if (iDuration > 1000) iDuration = 1000;
				if (iDuration == 0) return false; //FX: si l'on est déjà à la bonne position, on arrête tout

				//animation pour se déplacer en douceur jusqu'à la cible, par défaut on est en "swing" (accélération puis décélération)
				$('html,body').animate({
					scrollTop: (target.offset().top - iDecal)
				}, iDuration, "swing").promise().then(function () {
					//APE - 24/02/16 - on donne le focus à l'élément ciblé pour que la tabulation suive un ordre logique
					if (target.attr("tabindex") == undefined || target.attr("tabindex") == null || target.attr("tabindex") == "") {
						if (target.is(":hidden") || target.is(":disabled") || !target.is(":input, a[href], area[href], iframe")) {
							target.attr("tabindex", "-1"); //si l'élément ne pouvait pas prendre le focus, on fait en sorte qu'il puisse
							target.css("outline", "none"); //mais on ne le montre pas
						}
					}
					target.focus();
					$('html,body').scrollTop(target.offset().top - iDecal); //FX: Sinon IE se positionne sur l'ancre à la prise de focus. Ce n'est pas très éléguants et ça fait un moche effet visuel (sous IE) mais je n'ai rien trouvé d'autre ...
				});
				//return false;
			}
		});
}

/* gestion des ancres (à supprimer bientôt car sera intégré par M150*/
/* Author: Aurélien PÊCHEUR */
// InitSmoothScroll = function () {
InitSmoothScroll = function () {
	try {
		//décalage lié au header sticky
		var stickyHeight = 0;

		if (typeof $("#ei_tpl_head").attr('data-sticky') == 'undefined')
			stickyHeight = $('#ei_tpl_head').outerHeight(); //hauteur du header

		$("*[data-sticky]:visible").each(function () {
			stickyHeight += $(this).outerHeight();
		});

		//cas de l'arrivée sur une page avec une ancre dans l'url : le placement est déjà presque correct, il reste à ajouter le décalage
		var sId = decodeURIComponent(window.location.hash);
		sId = sId.replace(/\:/g, "\\:"); // Pour évitter que les id à la #!@ de devbooster ne fasse cracher jquery
		var target = $(sId);
		target = target.length ? target : $('[name=\'' + sId.slice(1) + '\']');
		if (target.length) {
			//on a bien une ancre dans l'url

			//Cas d'une vidéo à lancer en autoplay
			if (sId.indexOf("#autoplay-") != -1) {
				var lienVideo = target.parent().find('iframe').attr("src") + "&autoplay=1";
				target.parent().find('iframe').attr("src", lienVideo);
			}
			//cas d'une ancre classique
			if (!target.hasClass("ei_decal_anchor")) {
				target.focus();
				$('html,body').scrollTop(target.offset().top - (stickyHeight + 10));
				//IE et chrome retournent quand même à l'ancre... du coup astuce avec l'historique
				if (history.pushState) {
					history.pushState(null, null, sId);
					history.back();
				}
			}
		}

		// sélection des liens de type ancre, différent de "#" et qui restent sur la page
		Scroll2Hash();

	} catch (e) {
	}
};

try {
	$(document).ready(InitSmoothScroll);
	$(document).ajaxStop(InitSmoothScroll);
} catch (ex) {
}



/*==========================================================================
5. Ouverture des liens externes en target blank
==========================================================================*/
//pour les liens qui n'ont pas déjà été codé en target="blank" "en dur"
function openExternalLinksBlank() {
	$('.cc a').each(function () {
		var a = new RegExp('/' + window.location.host + '/');
		var b = new RegExp('\.pdf$');
		var c = new RegExp('^http'); //pour traiter uniquement les "vrais" liens, pas les liens incorrectements utilisés avec du javascripts
		//console.log("Lien "+this.href+"a:"+ a.test(this.href)+"b:"+ b.test(this.href)+"c:"+ c.test(this.href));

		//on ne traite que les liens qui n'ont pas déjà de target="blank"
		if ((!a.test(this.href) || b.test(this.href)) && c.test(this.href) && $(this).attr("target") != "_blank") {
			$(this).attr("target", "_blank");

			if ($(this).attr("title") != undefined && $(this).attr("title") != "")
				var title = $(this).attr("title") + ", Nouvelle fenêtre";
			else
				var title = $(this).text().replace(/\s+/g, ' ').trim() + ", Nouvelle fenêtre";

			$(this).attr("title", title);
		}
	});
}
$(document).ready(function () {
	openExternalLinksBlank();
});
